import React, { FunctionComponent, useMemo } from 'react';
import { PageHeader } from 'common.ui';
import './Page.scss';
import { Helmet } from 'react-helmet-async';

interface IProps {
  header?: string;

  title?: string | string[];
}
const Page: FunctionComponent<IProps> = ({ header, title, children }) => {
  const resolvedTitle = useMemo(() => {
    if (!title) {
      return 'Adgang';
    }

    const titleArray = Array.isArray(title) ? title : [title];
    const adgangTitleArray = [...titleArray, 'Adgang'].filter((t) => t); // Filter out empty strings

    return adgangTitleArray.join(' - ');
  }, [title]);

  return (
    <>
      <Helmet>
        <title>{resolvedTitle}</title>
      </Helmet>

      <div className='page-container'>
        {header && <PageHeader value={header} />}
        {children}
      </div>
    </>
  );
};

export default Page;
