import React from 'react';
import Page from 'components/page/Page';
import PermissionsForm from 'components/permissions/PermissionsForm';
import { AccessRequestApprovalType, PermissionCreateRequest, UserType } from 'api/adgang/models';
import { permissionsClient } from 'api/AdgangClients';
import { useHistory } from 'react-router-dom';
import RoutePaths from 'RoutePaths';

function AddPermission() {
  const history = useHistory();

  async function onSave(model: PermissionCreateRequest) {
    await permissionsClient.apiPermissionsPost({
      permissionCreateRequest: model
    });
    history.push(RoutePaths.permissions);
  }

  return (
    <Page header='Legg til ny tilgang' title='Legg til ny tilgang'>
      <PermissionsForm
        onSave={onSave}
        initialValue={{
          canBeRequestedByUsers: true,
          accessRequestApprovalType: AccessRequestApprovalType.LocalAdministrator,
          userType: UserType.Internal
        }}
        onSaveLabel='Legg til tilgang'
      />
    </Page>
  );
}

export default AddPermission;
