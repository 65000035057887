import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Button,
  PagedTable,
  Th,
  LoadingSpinner,
  useFetch,
  ConfirmationModal,
  ThSortable,
  useSortedPaging
} from 'common.ui';
import { organizationGroupsClient } from 'api/AdgangClients';
import { ReactComponent as IconDelete } from 'assets/icons/delete-icon.svg';
import Page from 'components/page/Page';
import { Container, Row, Col } from 'react-bootstrap';
import {
  AdgangAccessControl,
  ApiOrganizationGroupsGetRequest,
  OrganizationGroupResponse,
  OrganizationGroupResponsePagedResult,
  OrganizationGroupSearchOrderBy,
  ResourceFilter,
  SortOrder
} from 'api/adgang';
import { SortEvent } from 'common.ui/dist/components/tables/ThSortable';
import RoutePaths from 'RoutePaths';
import { permanentOrganizationGroups } from 'helpers/organizationGroup/organizationGroupHelpers';
import { useAccessControlList } from 'hooks/access/useAccessControlList';

function OrganizationGroups() {
  const [deleteOrganizationGroup, setDeleteOrganizationGroup] = useState<OrganizationGroupResponse>();

  const history = useHistory();
  const [, hasAccess] = useAccessControlList();

  const [organizationGroupsPaged, isLoading, , fetchOrganizationGroups] = useFetch<
    OrganizationGroupResponsePagedResult
  >(() => fetchPagedOrganizationGroups(), {}, false, []);

  const [
    startIndex,
    currentPage,
    pageSize,
    sorting,
    onNextPage,
    onPreviousPage,
    ,
    setSorting
  ] = useSortedPaging<OrganizationGroupSearchOrderBy>(fetchOrganizationGroups, {
    field: OrganizationGroupSearchOrderBy.Name,
    direction: 'asc'
  });

  const fetchPagedOrganizationGroups = async () => {
    const request = {
      pageNumber: currentPage,
      pageSize,
      sortOrder: sorting.direction === 'asc' ? SortOrder.Ascending : SortOrder.Descending,
      orderField: sorting.field,
      resourceFilter: ResourceFilter.OrganizationGroup
    } as ApiOrganizationGroupsGetRequest;
    return organizationGroupsClient.apiOrganizationGroupsGet(request);
  };

  function onCancelDelete() {
    setDeleteOrganizationGroup(undefined);
  }

  async function onDeleteConfirmed() {
    if (deleteOrganizationGroup?.organizationGroupId) {
      await organizationGroupsClient.apiOrganizationGroupsIdDelete({
        id: deleteOrganizationGroup.organizationGroupId
      });

      history.go(0); // refresh page
    }
  }

  const handleSort = (e: SortEvent<OrganizationGroupSearchOrderBy>) => {
    setSorting({ direction: e.direction, field: e.field });
  };

  return (
    <Page header='Organisasjonsgrupper' title='Organisasjonsgrupper'>
      <ConfirmationModal
        header='Slett organisasjonsgruppe'
        show={deleteOrganizationGroup !== undefined}
        onCancel={onCancelDelete}
        onAccept={onDeleteConfirmed}
      >
        Er du sikker på at du vil slett denne organisasjonsgruppen?
        <br />
        <span style={{ background: '#faf8dd' }}>
          ! Den er tilknyttet {deleteOrganizationGroup?.organizationCount}{' '}
          {deleteOrganizationGroup?.organizationCount === 1 ? 'organisasjon' : 'organisasjoner'}
        </span>
      </ConfirmationModal>

      <Container fluid>
        {hasAccess(AdgangAccessControl.OrganizationGroupsDelete) && (
          <Row>
            <Col sm={12}>
              <Button text='Legg til' onClick={() => history.push(RoutePaths.organizationgroupsAdd)} />
            </Col>
          </Row>
        )}

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <PagedTable
              startIndex={startIndex}
              totalHits={organizationGroupsPaged.pageInfo?.totalCount ?? 0}
              pageSize={pageSize}
              onNextClick={onNextPage}
              onPreviousClick={onPreviousPage}
            >
              <thead>
                <tr>
                  <ThSortable
                    title='Kortnavn'
                    field={OrganizationGroupSearchOrderBy.ShortName}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Organisasjonsgruppe'
                    field={OrganizationGroupSearchOrderBy.Name}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Utfyllende beskrivelse'
                    field={OrganizationGroupSearchOrderBy.Description}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <Th title='Antall org' />

                  <Th title='' />
                </tr>
              </thead>

              <tbody>
                {organizationGroupsPaged.data?.map((r) => (
                  <tr key={r.organizationGroupId ?? ''}>
                    <td>
                      <Link to={`${RoutePaths.organizationgroups}/${r.organizationGroupId}`}>
                        {r.shortName}
                      </Link>
                    </td>
                    <td>{r.name}</td>
                    <td>{r.description}</td>
                    <td>{r.organizationCount}</td>
                    <td>
                      {!r.organizationGroupType ||
                        (!permanentOrganizationGroups.includes(r.organizationGroupType) &&
                          hasAccess(AdgangAccessControl.OrganizationGroupsDelete) && (
                            <IconDelete
                              onClick={() => setDeleteOrganizationGroup(r)}
                              style={{ cursor: 'pointer' }}
                            />
                          ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </PagedTable>
          </>
        )}
      </Container>
    </Page>
  );
}

export default OrganizationGroups;
