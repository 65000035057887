import React, { useState } from 'react';
import {
  PagedTable,
  Button,
  LoadingSpinner,
  useFetch,
  SelectOption,
  FilterLabel,
  ConfirmationModal,
  SimpleModal,
  useBoundForm,
  FilterReset,
  ThSortable,
  Th,
  useSortedPaging
} from 'common.ui';
import { rolesClient } from 'api/AdgangClients';
import Page from 'components/page/Page';
import { Row, Col, Container } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useApplications, usePermissions } from 'api/hooks';
import { useSavedSearch } from 'hooks/search/useSavedSearch';
import { ReactComponent as IconDelete } from 'assets/icons/delete-icon.svg';

import { SortEvent } from 'common.ui/dist/components/tables/ThSortable';
import {
  AdgangAccessControl,
  ResourceFilter,
  RoleResponse,
  RoleResponsePagedResult,
  RoleSearchOrderBy,
  SortOrder,
  UserType,
  RoleConsumptionResponse
} from 'api/adgang';
import RoutePaths from 'RoutePaths';
import { useAccessControlList } from 'hooks/access/useAccessControlList';

type Search = {
  textFilter: string;
  applicationIds: string[];
  permissionIds: string[];
  userType?: UserType;
  seq: number;
};

interface IRolesScreen {
  applications: SelectOption[];
  initalSearch: Search;
  saveSearch: (value: Search) => void;
}

const emptySearch: Search = {
  applicationIds: [],
  permissionIds: [],
  seq: 0,
  textFilter: '',
  userType: undefined
};
const hasSearchFilters = (model: Search): boolean => {
  return !!(model.applicationIds.length || model.permissionIds.length || model.textFilter || model.userType);
};

function Roles() {
  const [, hasAccess] = useAccessControlList();

  const [applications] = useApplications(
    undefined,
    ResourceFilter.Role,
    !hasAccess(AdgangAccessControl.RolesViewShowNoAppFilter)
  );

  const [initialSearch, saveSearch] = useSavedSearch<Search>('roles_search', emptySearch);

  return applications ? (
    <RolesScreen applications={applications} initalSearch={initialSearch} saveSearch={saveSearch} />
  ) : (
    <LoadingSpinner />
  );
}

function RolesScreen({ applications, initalSearch, saveSearch }: IRolesScreen) {
  const [unvalidatedSearch, setSearch] = useState<Search>(initalSearch);
  const [deleteRole, setDeleteRole] = useState<RoleConsumptionResponse>();

  const [permissions] = usePermissions(
    undefined,
    unvalidatedSearch?.applicationIds,
    undefined,
    undefined,
    ResourceFilter.Role
  );

  const history = useHistory();

  const search = cleanSearch(unvalidatedSearch);

  const userTypes = [
    { id: UserType.Internal, text: 'Interne brukerroller' },
    { id: UserType.External, text: 'Eksterne brukerroller' }
  ];

  const [rolesPaged, isLoading, , fetchRoles] = useFetch<RoleResponsePagedResult>(
    () => fetchPagedRoles(),
    {} as RoleResponsePagedResult,
    false,
    [search.seq]
  );

  const [
    startIndex,
    currentPage,
    pageSize,
    sorting,
    onNextPage,
    onPreviousPage,
    setCurrentPage,
    setSorting
  ] = useSortedPaging<RoleSearchOrderBy>(fetchRoles, {
    field: RoleSearchOrderBy.ShortName,
    direction: 'asc'
  });

  const fetchPagedRoles = async (): Promise<RoleResponsePagedResult> => {
    const request = {
      roleSearchRequest: {
        paginationQuery: {
          pageSize,
          pageNumber: currentPage,
          sortOrder: sorting.direction === 'asc' ? SortOrder.Ascending : SortOrder.Descending,
          orderField: sorting.field
        },
        applicationFilter:
          search.applicationIds.length > 0
            ? {
                applicationIds: search.applicationIds.filter((a) => a !== 'global'),
                includeGlobalValues: search.applicationIds.some((a) => a === 'global')
              }
            : undefined,
        permissionIds: search.permissionIds.map((p) => parseInt(p, 10)),
        textFilter: search.textFilter,
        userType: search.userType,
        resourceFilter: ResourceFilter.Role
      }
    };
    return rolesClient.apiRolesSearchesPost(request);
  };

  const { form, FormContainer, RadioGroup, Input, MultiSelect } = useBoundForm<Search>({
    onSubmit: async (e) => {
      runSearch(e);
    },
    model: search
  });

  function cleanSearch(s: Search) {
    const model: Search = {
      ...s,
      // Only search for roles, that are valid options based on your role dropdown
      // (which can change dynamically whenever a user multiselects an application)
      permissionIds: s.permissionIds.filter((id) => permissions?.some((p) => p.id === id)),
      // a unselected UserType results in an empty string, not in undefined
      userType: s.userType === UserType.Internal || s.userType === UserType.External ? s.userType : undefined
    };
    return model;
  }

  async function onDeleteConfirmed() {
    if (deleteRole?.roleId) {
      await rolesClient.apiRolesIdDelete({
        id: deleteRole.roleId
      });
      history.go(0); // refresh page
    }
  }

  function renderNumber(result: JSX.Element[], num: number | undefined, text: string, pluralText: string) {
    const n = num ?? 0;
    if (n === 1) {
      result.push(<>1 {text}</>);
    } else if (n > 1) {
      result.push(
        <>
          {n} {pluralText}
        </>
      );
    }
  }

  function renderUsage(r: RoleConsumptionResponse | undefined) {
    if (r === undefined || !r.isConsumedByOtherEntitities) return <></>;

    const result: JSX.Element[] = [];
    renderNumber(result, r.numUserRoles, 'bruker', 'brukere');
    renderNumber(result, r.numActiveAccessRequests, 'rettighetsforespørsel', 'rettighetsforespørsler');
    renderNumber(result, r.numAdGroupRoles, 'AD-gruppe', 'AD-grupper');
    renderNumber(result, r.numSectionRoles, 'seksjon', 'seksjoner');

    return (
      <>
        <br />
        <span style={{ background: '#faf8dd' }}>
          ! Rollen er i bruk av:
          {result.map((ro, i) => (
            <>
              {i === 0 ? ' ' : ', '}
              {ro}
            </>
          ))}
        </span>
      </>
    );
  }

  async function requestDelete(role: RoleResponse) {
    const r = await rolesClient.apiRolesCheckusageGet({
      id: role.roleId
    });
    setDeleteRole(r);
  }

  const allowedToDeleteRole =
    deleteRole?.accessControls?.some((a) => a === AdgangAccessControl.RoleDelete) ?? false;

  function onCancelDelete() {
    setDeleteRole(undefined);
  }

  function runSearch(s: Search) {
    const model = cleanSearch(s);

    setCurrentPage(1);
    saveSearch({ ...model, seq: 0 });
    setSearch({ ...model, seq: search.seq + 1 });
  }

  const handleSort = (e: SortEvent<RoleSearchOrderBy>) => {
    setSorting({ direction: e.direction, field: e.field });
  };

  return (
    <Page header='Roller' title='Roller'>
      <ConfirmationModal
        header='Slett rolle'
        show={deleteRole !== undefined && allowedToDeleteRole}
        onCancel={onCancelDelete}
        onAccept={onDeleteConfirmed}
      >
        Er du sikker på at du vil slett denne rolle?
        {renderUsage(deleteRole)}
        <br />
      </ConfirmationModal>{' '}
      <SimpleModal
        header='Slett rolle'
        onCancel={onCancelDelete}
        show={deleteRole !== undefined && !allowedToDeleteRole}
      >
        Du har ikke lov til å slette denne rolle.
        {renderUsage(deleteRole)}
      </SimpleModal>
      <Container fluid>
        <FormContainer key={search.seq} form={form}>
          <Row>
            <Col sm={12} lg={4}>
              <Input
                form={form}
                name='textFilter'
                label='Søk'
                placeholder='Søker i alle tekstfelt'
                onEnterPressed={(e) => {
                  runSearch({
                    ...search,
                    textFilter: e,
                    seq: search.seq + 1
                  });
                }}
              />
            </Col>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='applicationIds'
                label='Applikasjon'
                okLabel='Vis resultater'
                options={applications}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    applicationIds: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='permissionIds'
                label='Tilgang'
                okLabel='Vis resultater'
                options={permissions ?? []}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    permissionIds: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <RadioGroup form={form} name='userType' label='Vis bare:' options={userTypes} />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              {search.textFilter && search.textFilter !== '' && (
                <FilterLabel
                  key='textFilter'
                  label={search.textFilter}
                  onRemove={() => {
                    runSearch({
                      ...search,
                      textFilter: '',
                      seq: search.seq + 1
                    });
                  }}
                />
              )}
              {search.applicationIds &&
                search.applicationIds.map((app) => (
                  <FilterLabel
                    key={`applicationId${app}`}
                    label={applications.filter((a) => a.id === app)[0]?.text}
                    onRemove={() => {
                      runSearch({
                        ...search,
                        applicationIds: search.applicationIds.filter((a) => a !== app),
                        seq: search.seq + 1
                      });
                    }}
                  />
                ))}
              {permissions &&
                search.permissionIds &&
                search.permissionIds.map((p) => (
                  <FilterLabel
                    key={`permissionId${p}`}
                    label={permissions.filter((pe) => pe.id === p)[0]?.text}
                    onRemove={() => {
                      runSearch({
                        ...search,
                        permissionIds: search.permissionIds.filter((pe) => pe !== p),
                        seq: search.seq + 1
                      });
                    }}
                  />
                ))}
              {search.userType && (
                <FilterLabel
                  key='userType'
                  label={userTypes.filter((u) => u.id === search.userType)[0].text}
                  onRemove={() => {
                    runSearch({
                      ...search,
                      userType: undefined,
                      seq: search.seq + 1
                    });
                  }}
                />
              )}
              {hasSearchFilters(search) && (
                <FilterReset
                  key='reset'
                  label='Fjern alle'
                  onRemove={() => {
                    runSearch({
                      ...emptySearch
                    });
                  }}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Button type='submit' text='Søk' />
              <Button text='Legg til' onClick={() => history.push(RoutePaths.rolesAdd)} />
            </Col>
          </Row>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <PagedTable
              startIndex={startIndex}
              totalHits={rolesPaged.pageInfo?.totalCount ?? 0}
              pageSize={pageSize}
              onNextClick={onNextPage}
              onPreviousClick={onPreviousPage}
            >
              <thead>
                <tr>
                  <ThSortable
                    title='Rollenavn'
                    field={RoleSearchOrderBy.Name}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Kortnavn'
                    field={RoleSearchOrderBy.ShortName}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Applikasjon'
                    field={RoleSearchOrderBy.ApplicationName}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Intern/ekstern'
                    field={RoleSearchOrderBy.UserType}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <Th title='' />
                </tr>
              </thead>

              <tbody>
                {rolesPaged.data?.map((r) => (
                  <tr key={r.roleId ?? ''}>
                    <td>
                      <Link to={`${RoutePaths.roles}/${r.roleId}`}>{r.name}</Link>
                    </td>
                    <td>{r.shortName}</td>
                    <td>{r.applicationName}</td>
                    <td>
                      {r.userType === UserType.Internal ? 'Intern' : ''}
                      {r.userType === UserType.External ? 'Ekstern' : ''}
                    </td>
                    <td>
                      <IconDelete onClick={() => requestDelete(r)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </PagedTable>
          )}
        </FormContainer>
      </Container>
    </Page>
  );
}

export default Roles;
