import React, { useMemo } from 'react';
import Page from 'components/page/Page';
import { UserClaimsList } from 'components/users/UserClaimsList';
import { LoadingSpinner, useFetch } from 'common.ui';
import { internalUsersClient, usersClient } from 'api/AdgangClients';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';

interface IParams {
  id: string;
}

function InternalUserClaims() {
  const { id } = useParams<IParams>();
  const [user] = useFetch(
    async () =>
      internalUsersClient.apiInternalUsersInternalUserIdGet({
        internalUserId: id
      }),
    undefined,
    false,
    [id]
  );

  const [claims] = useFetch(
    async () =>
      user?.userId
        ? usersClient.apiUsersUserIdAccessClaimsGet({
            userId: user.userId
          })
        : null,
    undefined,
    false,
    [user?.userId]
  );

  const fullName = useMemo(() => {
    if (user) {
      return `${user.givenName} ${user.surname}`;
    }
    return '';
  }, [user]);

  return (
    <Page header='Intern ansatt Claims' title={['Intern ansatt', fullName, 'Claims']}>
      {user && claims ? (
        <>
          <Container fluid>
            <Row>
              <Col lg='12'>
                <UserClaimsList claims={claims} userId={user.userId ?? ''} />
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </Page>
  );
}

export default InternalUserClaims;
