/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExternalUserEmailUpdateRequest,
    ExternalUserEmailUpdateRequestFromJSON,
    ExternalUserEmailUpdateRequestToJSON,
    ExternalUserEmailUpdateResponse,
    ExternalUserEmailUpdateResponseFromJSON,
    ExternalUserEmailUpdateResponseToJSON,
    ExternalUserNameUpdateRequest,
    ExternalUserNameUpdateRequestFromJSON,
    ExternalUserNameUpdateRequestToJSON,
    ExternalUserNameUpdateResponse,
    ExternalUserNameUpdateResponseFromJSON,
    ExternalUserNameUpdateResponseToJSON,
    GenericValidationError,
    GenericValidationErrorFromJSON,
    GenericValidationErrorToJSON,
    NotFoundResult,
    NotFoundResultFromJSON,
    NotFoundResultToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface ApiUserB2cProfileUserIdEmailPutRequest {
    userId: string;
    externalUserEmailUpdateRequest?: ExternalUserEmailUpdateRequest;
}

export interface ApiUserB2cProfileUserIdNamePutRequest {
    userId: string;
    externalUserNameUpdateRequest?: ExternalUserNameUpdateRequest;
}

export interface ApiUserB2cProfileUserIdPhoneDeleteRequest {
    userId: string;
}

/**
 * 
 */
export class UserB2cProfileApi extends runtime.BaseAPI {

    /**
     */
    async apiUserB2cProfileUserIdEmailPutRaw(requestParameters: ApiUserB2cProfileUserIdEmailPutRequest): Promise<runtime.ApiResponse<ExternalUserEmailUpdateResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUserB2cProfileUserIdEmailPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-b2c-profile/{userId}/email`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalUserEmailUpdateRequestToJSON(requestParameters.externalUserEmailUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalUserEmailUpdateResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiUserB2cProfileUserIdEmailPut(requestParameters: ApiUserB2cProfileUserIdEmailPutRequest): Promise<ExternalUserEmailUpdateResponse> {
        const response = await this.apiUserB2cProfileUserIdEmailPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUserB2cProfileUserIdNamePutRaw(requestParameters: ApiUserB2cProfileUserIdNamePutRequest): Promise<runtime.ApiResponse<ExternalUserNameUpdateResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUserB2cProfileUserIdNamePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user-b2c-profile/{userId}/name`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalUserNameUpdateRequestToJSON(requestParameters.externalUserNameUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalUserNameUpdateResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiUserB2cProfileUserIdNamePut(requestParameters: ApiUserB2cProfileUserIdNamePutRequest): Promise<ExternalUserNameUpdateResponse> {
        const response = await this.apiUserB2cProfileUserIdNamePutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUserB2cProfileUserIdPhoneDeleteRaw(requestParameters: ApiUserB2cProfileUserIdPhoneDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiUserB2cProfileUserIdPhoneDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/user-b2c-profile/{userId}/phone`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUserB2cProfileUserIdPhoneDelete(requestParameters: ApiUserB2cProfileUserIdPhoneDeleteRequest): Promise<void> {
        await this.apiUserB2cProfileUserIdPhoneDeleteRaw(requestParameters);
    }

}
