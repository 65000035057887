import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import {
  TranslationResponsePagedResult,
  TranslationSearchOrderBy,
  SortOrder,
  TranslationResponse,
  AdgangAccessControl,
  UserEventExpirationResponse,
  TranslationGroupType
} from 'api/adgang/models';
import { ButtonLink, LoadingSpinner, PagedTable, Th, useFetch, useSortedPaging, Dropdown } from 'common.ui';
import { translationsClient, userEventExpiryClient } from 'api/AdgangClients';
import { ApiTranslationsEditPutRequest, ApiTranslationsSearchPostRequest } from 'api/adgang';
import { concat } from 'helpers/concat';
import Page from 'components/page/Page';
import { EditTextPopupModal } from 'components/uitexts/EditTextPopupModal';
import { useAccessControlList } from 'hooks/access/useAccessControlList';

function UiTexts() {
  const [seq, setSeq] = useState<number>(0);
  const [isSavingExpiry, setIsSavingExpiry] = useState(false);
  const [editValue, setEditValue] = useState<TranslationResponse | undefined>(undefined);
  const [, hasAccess] = useAccessControlList();
  const [usersPaged, isLoading, , fetchTranslations] = useFetch<TranslationResponsePagedResult>(
    () => fetchPagedTranslations(),
    {} as TranslationResponsePagedResult,
    false,
    [seq]
  );

  const [eventExpiries, isLoadingEventExpiries, ,] = useFetch<UserEventExpirationResponse[]>(
    () => fetchEventExpiry(),
    [],
    false,
    [seq]
  );

  const [startIndex, currentPage, pageSize, sorting, onNextPage, onPreviousPage] = useSortedPaging<
    TranslationSearchOrderBy
  >(fetchTranslations, {
    field: TranslationSearchOrderBy.Default,
    direction: 'asc'
  });

  const fetchPagedTranslations = async () => {
    const request: ApiTranslationsSearchPostRequest = {
      translationsSearchRequest: {
        paginationQuery: {
          pageNumber: currentPage,
          pageSize,
          sortOrder: sorting.direction === 'asc' ? SortOrder.Ascending : SortOrder.Descending,
          orderField: sorting.field
        }
      }
    };
    return translationsClient.apiTranslationsSearchPost(request);
  };

  const fetchEventExpiry = async () => {
    return userEventExpiryClient.apiUserEventExpirySearchPost();
  };

  const setExpiry = async (translation: TranslationResponse, expiryInDays?: number) => {
    if (translation.groupKey) {
      setIsSavingExpiry(true);
      await userEventExpiryClient.apiUserEventExpiryEditPut({
        userEventExpirationUpdateRequest: {
          translationResourceKey: translation.groupKey,
          expiryInDays: expiryInDays === 0 ? null : expiryInDays
        }
      });
      setIsSavingExpiry(false);
    }
  };
  const ExpiryDropdown = (translation: TranslationResponse) => {
    if (translation.groupType === TranslationGroupType.UserEventMessages) {
      const existingValue =
        eventExpiries.find((e) => e.translationResourceKey === translation.groupKey)?.expiryInDays ?? 0;

      const options = [
        {
          id: '0',
          text: 'Aldri'
        },
        {
          id: '1',
          text: '1 dage'
        },
        {
          id: '30',
          text: '30 dager'
        },
        {
          id: '90',
          text: '90 dager'
        }
      ];

      if (!options.find((o) => o.id === existingValue.toString())) {
        options.push({
          id: existingValue.toString(),
          text: `${existingValue} dager`
        });
      }
      return (
        <>
          <Dropdown
            id={translation.groupKey?.toString() ?? ''}
            labelText=''
            value={existingValue.toString()}
            onChange={(e) => setExpiry(translation, parseInt(e.target.value, 10))}
            options={options}
          />
        </>
      );
    }
    return <></>;
  };
  return (
    <Page header='Tekster' title='Tekster'>
      <Container fluid>
        {isLoading || isLoadingEventExpiries ? (
          <LoadingSpinner />
        ) : (
          <>
            {editValue !== undefined && (
              <EditTextPopupModal
                show
                item={editValue}
                onCancel={() => {
                  setEditValue(undefined);
                }}
                onAccept={async (result: string) => {
                  if (editValue.languageId === undefined || editValue.groupType === undefined) {
                    return;
                  }

                  const request: ApiTranslationsEditPutRequest = {
                    translationUpdateRequest: {
                      languageId: editValue.languageId,
                      groupType: editValue.groupType,
                      groupKey: editValue.groupKey,
                      text: result
                    }
                  };
                  await translationsClient.apiTranslationsEditPut(request);

                  setSeq((v) => v + 1);

                  setEditValue(undefined);
                }}
              />
            )}
            <div
              style={
                isSavingExpiry
                  ? {
                      opacity: '0.5',
                      pointerEvents: 'none'
                    }
                  : {}
              }
            >
              {isSavingExpiry && (
                <div style={{ position: 'absolute', left: 'calc(50% - 100px)' }}>
                  <LoadingSpinner />
                </div>
              )}
              <PagedTable
                startIndex={startIndex}
                totalHits={usersPaged.pageInfo?.totalCount ?? 0}
                pageSize={pageSize}
                onNextClick={onNextPage}
                onPreviousClick={onPreviousPage}
              >
                <thead>
                  <tr>
                    <Th title='Gruppe' />
                    <Th title='Tekst' />
                    <Th title='Plassholdere' />
                    <Th title='Utløp' />
                    <Th title='' />
                  </tr>
                </thead>

                <tbody>
                  {usersPaged.data?.map((u) => (
                    <tr key={concat('_', u.groupType || '', String(u.groupKey || 0))}>
                      <td>{u.groupText}</td>
                      <td>{u.text}</td>
                      <td>
                        <ul style={{ paddingLeft: 0, marginLeft: '1em' }}>
                          {u.placeholders &&
                            Object.keys(u.placeholders).map((x) => (
                              <li key={x}>
                                &#123;{x}&#125;: {u.placeholders ? u.placeholders[x] : ''}
                              </li>
                            ))}
                        </ul>
                      </td>
                      <td style={{ minWidth: '140px' }}>{ExpiryDropdown(u)}</td>
                      <td>
                        {hasAccess(AdgangAccessControl.TranslationsEdit) && (
                          <ButtonLink text='Endre' onClick={() => setEditValue(u)} />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </PagedTable>
            </div>
          </>
        )}
      </Container>
    </Page>
  );
}

export default UiTexts;
