const errorHandlers: ((msg: string) => void)[] = [];

export function RegisterErrorCallback(callback: (msg: string) => void) {
  errorHandlers.push(callback);
}

export function RegisterError(msg: Event | string) {
  for (const cb of errorHandlers) {
    if (typeof msg === 'string') {
      cb(msg);
    }
  }
}
