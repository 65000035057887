import React from 'react';
import Page from 'components/page/Page';
import { AccessRequestApprovalType, RoleCreateRequest, UserType } from 'api/adgang/models';

import RoleForm from 'components/roles/RoleForm';
import { rolesClient } from 'api/AdgangClients';
import { useHistory } from 'react-router-dom';
import RoutePaths from 'RoutePaths';

function AddRole() {
  const history = useHistory();

  async function onSave(model: RoleCreateRequest) {
    await rolesClient.apiRolesPost({
      roleCreateRequest: model
    });
    history.push(RoutePaths.roles);
  }

  return (
    <Page header='Legg til rolle' title='Legg til rolle'>
      <RoleForm<RoleCreateRequest>
        onSave={onSave}
        initialValue={{
          userType: UserType.Internal,
          name: '',
          applicationId: 'global',
          canBeRequestedByUsers: true,
          accessRequestApprovalType: AccessRequestApprovalType.LocalAdministrator,
          description: '',
          tags: '',
          shortName: '',
          permissionIds: []
        }}
        onSaveLabel='Legg til rolle'
      />
    </Page>
  );
}

export default AddRole;
