/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRequestApprovalType,
    AccessRequestApprovalTypeFromJSON,
    AccessRequestApprovalTypeFromJSONTyped,
    AccessRequestApprovalTypeToJSON,
    UserAuthenticationConstraint,
    UserAuthenticationConstraintFromJSON,
    UserAuthenticationConstraintFromJSONTyped,
    UserAuthenticationConstraintToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface PermissionCreateRequest
 */
export interface PermissionCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof PermissionCreateRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionCreateRequest
     */
    shortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionCreateRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionCreateRequest
     */
    applicationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionCreateRequest
     */
    tags?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof PermissionCreateRequest
     */
    userType?: UserType;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionCreateRequest
     */
    canBeRequestedByUsers?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PermissionCreateRequest
     */
    organizationGroupId?: number | null;
    /**
     * 
     * @type {UserAuthenticationConstraint}
     * @memberof PermissionCreateRequest
     */
    userAuthenticationConstraint?: UserAuthenticationConstraint;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionCreateRequest
     */
    requireUserOrganizationInMinSide?: boolean | null;
    /**
     * 
     * @type {AccessRequestApprovalType}
     * @memberof PermissionCreateRequest
     */
    accessRequestApprovalType?: AccessRequestApprovalType;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionCreateRequest
     */
    requiresMfa?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionCreateRequest
     */
    allowEndUserCreation?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PermissionCreateRequest
     */
    accessExpirationId?: number | null;
}

export function PermissionCreateRequestFromJSON(json: any): PermissionCreateRequest {
    return PermissionCreateRequestFromJSONTyped(json, false);
}

export function PermissionCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'userType': !exists(json, 'userType') ? undefined : UserTypeFromJSON(json['userType']),
        'canBeRequestedByUsers': !exists(json, 'canBeRequestedByUsers') ? undefined : json['canBeRequestedByUsers'],
        'organizationGroupId': !exists(json, 'organizationGroupId') ? undefined : json['organizationGroupId'],
        'userAuthenticationConstraint': !exists(json, 'userAuthenticationConstraint') ? undefined : UserAuthenticationConstraintFromJSON(json['userAuthenticationConstraint']),
        'requireUserOrganizationInMinSide': !exists(json, 'requireUserOrganizationInMinSide') ? undefined : json['requireUserOrganizationInMinSide'],
        'accessRequestApprovalType': !exists(json, 'accessRequestApprovalType') ? undefined : AccessRequestApprovalTypeFromJSON(json['accessRequestApprovalType']),
        'requiresMfa': !exists(json, 'requiresMfa') ? undefined : json['requiresMfa'],
        'allowEndUserCreation': !exists(json, 'allowEndUserCreation') ? undefined : json['allowEndUserCreation'],
        'accessExpirationId': !exists(json, 'accessExpirationId') ? undefined : json['accessExpirationId'],
    };
}

export function PermissionCreateRequestToJSON(value?: PermissionCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'shortName': value.shortName,
        'description': value.description,
        'applicationId': value.applicationId,
        'tags': value.tags,
        'userType': UserTypeToJSON(value.userType),
        'canBeRequestedByUsers': value.canBeRequestedByUsers,
        'organizationGroupId': value.organizationGroupId,
        'userAuthenticationConstraint': UserAuthenticationConstraintToJSON(value.userAuthenticationConstraint),
        'requireUserOrganizationInMinSide': value.requireUserOrganizationInMinSide,
        'accessRequestApprovalType': AccessRequestApprovalTypeToJSON(value.accessRequestApprovalType),
        'requiresMfa': value.requiresMfa,
        'allowEndUserCreation': value.allowEndUserCreation,
        'accessExpirationId': value.accessExpirationId,
    };
}


