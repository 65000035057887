/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalUserNameUpdateResponse
 */
export interface ExternalUserNameUpdateResponse {
    /**
     * 
     * @type {string}
     * @memberof ExternalUserNameUpdateResponse
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalUserNameUpdateResponse
     */
    displayName?: string | null;
}

export function ExternalUserNameUpdateResponseFromJSON(json: any): ExternalUserNameUpdateResponse {
    return ExternalUserNameUpdateResponseFromJSONTyped(json, false);
}

export function ExternalUserNameUpdateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalUserNameUpdateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
    };
}

export function ExternalUserNameUpdateResponseToJSON(value?: ExternalUserNameUpdateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'displayName': value.displayName,
    };
}


