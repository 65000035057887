import React, { useEffect, useState } from 'react';
import {
  LoadingSpinner,
  useFetch,
  PagedTable,
  ThSortable,
  useSortedPaging,
  Th,
  formatDatetime,
  ConfirmationModal,
  Message,
  SelectOption,
  useBoundForm
} from 'common.ui';
import { accessRequestsClient, usersClient } from 'api/AdgangClients';
import {
  AccessRequestResponse,
  AccessRequestResponsePagedResult,
  AdgangAccessControl,
  ApprovedAccessOrderBy,
  ApprovedAccessResponse,
  ApprovedAccessResponsePagedResult,
  GenericValidationError,
  ResourceFilter,
  SortOrder,
  UserAccessType
} from 'api/adgang';
import Page from 'components/page/Page';
import { ReactComponent as IconDelete } from 'assets/icons/delete-icon.svg';

import { Col, Container, Row } from 'react-bootstrap';
import { SortEvent } from 'common.ui/dist/components/tables/ThSortable';
import { useAccessControlList } from 'hooks/access/useAccessControlList';
import { useApplications } from 'api/hooks';

type ApprovedAccessForm = {
  applicationId: string | undefined;
  seq: number;
};

function ApprovedAccessRequests() {
  const [values, setValues] = useState<ApprovedAccessForm>({
    applicationId: undefined,
    seq: 0
  });
  const [accessRequestsPaged, isLoading, , fetchAccessRequests] = useFetch<ApprovedAccessResponsePagedResult>(
    () => fetchPagedAccessRequests(),
    {} as AccessRequestResponsePagedResult,
    false,
    [values.applicationId]
  );

  const [, hasAccess] = useAccessControlList();
  const [applications] = useApplications(
    undefined,
    ResourceFilter.AccessRequest,
    !hasAccess(AdgangAccessControl.AccessRequestsViewShowNoAppFilter)
  );

  const [
    startIndex,
    currentPage,
    pageSize,
    sorting,
    onNextPage,
    onPreviousPage,
    setCurrentpage,
    setSorting
  ] = useSortedPaging<ApprovedAccessOrderBy>(
    fetchAccessRequests,
    {
      field: ApprovedAccessOrderBy.AssignedOn,
      direction: 'asc'
    },
    undefined,
    undefined,
    true
  );

  useEffect(() => {
    if (applications && applications.length > 0) setValues({ ...values, applicationId: applications[0].id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applications]);

  const fetchPagedAccessRequests = async (): Promise<ApprovedAccessResponsePagedResult> => {
    if (!applications) return {} as ApprovedAccessResponsePagedResult;

    return accessRequestsClient.apiAccessRequestsApprovedPost({
      approvedAccessSearchRequest: {
        paginationQuery: {
          pageSize,
          pageNumber: currentPage,
          sortOrder: sorting.direction === 'asc' ? SortOrder.Ascending : SortOrder.Descending,
          orderField: sorting.field
        },
        applicationFilter: {
          applicationIds:
            values?.applicationId && values?.applicationId !== 'global' ? [values?.applicationId] : [],
          includeGlobalValues: values?.applicationId === 'global'
        }
      }
    });
  };

  const handleSort = (e: SortEvent<ApprovedAccessOrderBy>) => {
    setSorting({ direction: e.direction, field: e.field });
  };

  function runSearch(s: ApprovedAccessForm) {
    setCurrentpage(1);
    setValues({ ...s, seq: values.seq + 1 });
  }

  const { form, FormContainer, Dropdown } = useBoundForm<ApprovedAccessForm>({
    onSubmit: async (e) => {
      runSearch(e);
    },
    model: values
  });

  const [removeAccess, setRemoveAccess] = useState<AccessRequestResponse | undefined>();

  return (
    <Page header='Gitte rettigheter' title='Gitte rettigheter'>
      <Container fluid>
        {isLoading || !applications ? (
          <LoadingSpinner />
        ) : (
          <>
            <Row>
              <Col sm={12} lg={4}>
                <FormContainer form={form}>
                  <Dropdown
                    form={form}
                    onChange={(e) => {
                      setCurrentpage(1);
                      setValues({ ...values, applicationId: e.target.value });
                    }}
                    options={(applications as SelectOption[]) ?? []}
                    label='Applikasjon'
                    name='applicationId'
                  />
                </FormContainer>
              </Col>
            </Row>
            <PagedTable
              startIndex={startIndex}
              totalHits={accessRequestsPaged.pageInfo?.totalCount ?? 0}
              pageSize={pageSize}
              onNextClick={onNextPage}
              onPreviousClick={onPreviousPage}
            >
              <thead>
                <tr>
                  <ThSortable
                    title='Navn'
                    currentSort={sorting}
                    onSort={handleSort}
                    field={ApprovedAccessOrderBy.UserDisplayName}
                  />
                  <ThSortable
                    title='E-postadresse'
                    currentSort={sorting}
                    onSort={handleSort}
                    field={ApprovedAccessOrderBy.UserEmail}
                  />
                  <ThSortable
                    title='Telefonnummer'
                    currentSort={sorting}
                    onSort={handleSort}
                    field={ApprovedAccessOrderBy.UserMfaPhone}
                  />
                  <ThSortable
                    title='Rettighet/Organisasjon'
                    currentSort={sorting}
                    onSort={handleSort}
                    field={ApprovedAccessOrderBy.AccessName}
                  />
                  <ThSortable
                    title='Applikasjonsnavn'
                    currentSort={sorting}
                    onSort={handleSort}
                    field={ApprovedAccessOrderBy.ApplicationName}
                  />
                  <ThSortable
                    title='Dato'
                    currentSort={sorting}
                    onSort={handleSort}
                    field={ApprovedAccessOrderBy.AssignedOn}
                  />
                  <Th title='Fjern' />
                </tr>
              </thead>

              <tbody>
                {accessRequestsPaged?.data?.map((u) => (
                  <tr key={`${u.accessType}${u.accessId}${u.userId}${u.organizationId}`}>
                    <td>{u.userDisplayName}</td>
                    <td>{u.userEmail}</td>
                    <td>{u.userMfaPhone}</td>
                    <td>
                      {u.accessName}
                      {u.organizationName ? ` / ${u.organizationName}` : ''}
                    </td>
                    <td>{u.applicationName}</td>
                    <td>{u.assignedOn && formatDatetime(u.assignedOn, true)}</td>
                    <td>
                      <IconDelete onClick={() => setRemoveAccess(u)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </PagedTable>

            <RemoveAccessModal
              accessRequest={removeAccess}
              onDeleteCancelled={() => setRemoveAccess(undefined)}
              onDeleteSuccess={() => {
                setRemoveAccess(undefined);
                fetchAccessRequests();
              }}
            />
          </>
        )}
      </Container>
    </Page>
  );
}

function RemoveAccessModal({
  accessRequest,
  onDeleteCancelled,
  onDeleteSuccess
}: {
  accessRequest: ApprovedAccessResponse | undefined;
  onDeleteCancelled: () => void;
  onDeleteSuccess: () => void;
}) {
  const [error, setError] = useState<GenericValidationError | undefined>(undefined);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const removeUserRight = async (toDelete: ApprovedAccessResponse): Promise<void> => {
    if (toDelete.accessType === UserAccessType.Role && toDelete.organizationId) {
      await usersClient.apiUsersUserIdRolesRoleIdOrganizationOrganizationIdDelete({
        roleId: toDelete.accessId as number,
        userId: toDelete.userId as string,
        organizationId: toDelete?.organizationId
      });
    } else if (toDelete.accessType === UserAccessType.Permission && toDelete.organizationId) {
      await usersClient.apiUsersUserIdPermissionsPermissionIdOrganizationOrganizationIdDelete({
        permissionId: toDelete.accessId as number,
        userId: toDelete.userId as string,
        organizationId: toDelete?.organizationId
      });
    } else if (toDelete.accessType === UserAccessType.Permission) {
      await usersClient.apiUsersUserIdPermissionsPermissionIdDelete({
        permissionId: toDelete.accessId as number,
        userId: toDelete.userId as string
      });
    } else if (toDelete.accessType === UserAccessType.Role) {
      await usersClient.apiUsersUserIdRolesRoleIdDelete({
        roleId: toDelete.accessId as number,
        userId: toDelete?.userId as string
      });
    }
  };

  async function onDeleteConfirmed() {
    if (accessRequest) {
      setDeleteLoading(true);
      let isError = false;
      try {
        setError(undefined);
        await removeUserRight(accessRequest);
      } catch (e) {
        isError = true;
        if (e instanceof Response && e.status === 400) {
          const apiError = (await e.json()) as GenericValidationError;
          if (apiError) {
            setError(apiError);
          }
        }
      } finally {
        setDeleteLoading(false);
        if (!isError) onDeleteSuccess();
      }
    }
  }

  return (
    <ConfirmationModal
      header='Fjern rettighet fra brukeren'
      show={!!accessRequest}
      onCancel={onDeleteCancelled}
      onAccept={onDeleteConfirmed}
      disabled={error !== undefined}
      hideButtons={deleteLoading}
    >
      {!error && (
        <>
          <p> Er du sikker på at du vil fjerne rettighet fra brukeren? </p>
          <div>
            <div>
              Bruker: <strong>{accessRequest?.userDisplayName}</strong>
            </div>
            <div>
              Rettighet: <strong>{accessRequest?.accessName}</strong>
            </div>
            <div>
              Applikasjon: <strong>{accessRequest?.applicationName}</strong>
            </div>
            {accessRequest?.organizationId && (
              <div>
                På vegne av: <strong>{accessRequest?.organizationName}</strong>
              </div>
            )}
          </div>
        </>
      )}
      {deleteLoading && <LoadingSpinner />}
      {error?.messages && error.messages.length > 0 && (
        <Message key='app_delete_err' text={error.messages[0]} type='error' />
      )}
    </ConfirmationModal>
  );
}

export default ApprovedAccessRequests;
