import React, { useState } from 'react';
import {
  PagedTable,
  Button,
  LoadingSpinner,
  useFetch,
  SelectOption,
  FilterLabel,
  useBoundForm,
  FilterReset,
  ThSortable,
  useSortedPaging
} from 'common.ui';
import { externalUsersClient } from 'api/AdgangClients';
import Page from 'components/page/Page';
import { Row, Col, Container } from 'react-bootstrap';
import {
  useRoles,
  useExternalUserStatus,
  usePermissions,
  useOrganizationGroups,
  useApplications
} from 'api/hooks';
import { Link } from 'react-router-dom';
import { useSavedSearch } from 'hooks/search/useSavedSearch';
import { SortEvent } from 'common.ui/dist/components/tables/ThSortable';
import {
  AdgangAccessControl,
  ApiExternalUsersSearchesPostRequest,
  ExternalUserResponsePagedResult,
  ExternalUserSearchOrderBy,
  ExternalUserStatus,
  ResourceFilter,
  SortOrder,
  UserType
} from 'api/adgang';
import RoutePaths from 'RoutePaths';
import { useAccessControlList } from 'hooks/access/useAccessControlList';

type Search = {
  organization: string;
  firstName: string;
  surname: string;
  statuses: string[];
  organizationGroups: string[];
  roles: string[];
  permissions: string[];
  applications: string[];
  seq: number;
};

interface IUsersScreen {
  applications: SelectOption[];
  organizationGroups: SelectOption[];
  initalSearch: Search;
  saveSearch: (value: Search) => void;
}

const emptySearch: Search = {
  organizationGroups: [],
  statuses: [],
  roles: [],
  applications: [],
  permissions: [],
  seq: 0,
  firstName: '',
  surname: '',
  organization: ''
};
const hasSearchFilters = (model: Search): boolean => {
  return !!(
    model.organizationGroups.length ||
    model.statuses.length ||
    model.roles.length ||
    model.applications.length ||
    model.permissions.length ||
    model.firstName ||
    model.surname ||
    model.organization
  );
};

function ExternalUsers() {
  const [, hasAccess] = useAccessControlList();

  const [organizationGroups] = useOrganizationGroups();
  const [applications] = useApplications(
    undefined,
    ResourceFilter.ExternalUser,
    !hasAccess(AdgangAccessControl.ExternalUsersViewShowNoAppFilter)
  );

  const [initialSearch, saveSearch] = useSavedSearch<Search>('external_users_search', emptySearch);

  return organizationGroups && applications ? (
    <UsersScreen
      applications={applications}
      initalSearch={initialSearch}
      organizationGroups={organizationGroups}
      saveSearch={saveSearch}
    />
  ) : (
    <LoadingSpinner />
  );
}

function UsersScreen({ organizationGroups, applications, initalSearch, saveSearch }: IUsersScreen) {
  const [unvalidatedSearch, setSearch] = useState<Search>(initalSearch);
  const [roles] = useRoles(
    UserType.External,
    unvalidatedSearch?.applications,
    undefined,
    ResourceFilter.ExternalUser
  );
  const [permissions] = usePermissions(UserType.External, unvalidatedSearch?.applications);

  const search = cleanSearch(unvalidatedSearch);

  const [userStatuses] = useExternalUserStatus();

  const [usersPaged, isLoading, , fetchUsers] = useFetch<ExternalUserResponsePagedResult>(
    () => fetchPagedUsers(),
    {},
    false,
    [search.seq]
  );

  const [
    startIndex,
    currentPage,
    pageSize,
    sorting,
    onNextPage,
    onPreviousPage,
    setCurrentPage,
    setSorting
  ] = useSortedPaging<ExternalUserSearchOrderBy>(fetchUsers, {
    field: ExternalUserSearchOrderBy.DisplayName,
    direction: 'asc'
  });

  const fetchPagedUsers = async () => {
    const request = {
      externalUserSearchRequest: {
        paginationQuery: {
          pageNumber: currentPage,
          pageSize,
          sortOrder: sorting.direction === 'asc' ? SortOrder.Ascending : SortOrder.Descending,
          orderField: sorting.field
        },
        firstname: search.firstName,
        surname: search.surname,
        statusFilter:
          search.statuses.length > 0 ? search.statuses.map((s) => s as ExternalUserStatus) : undefined,
        organizationName: search.organization,
        organizationGroupIds:
          search.organizationGroups.length > 0
            ? search.organizationGroups.map((og) => parseInt(og, 10))
            : undefined,

        applicationFilter:
          search.applications.length > 0
            ? {
                applicationIds: search.applications.filter((a) => a !== 'global'),
                includeGlobalValues: search.applications.some((a) => a === 'global')
              }
            : undefined,

        roleFilter:
          search.roles.length === 0
            ? undefined
            : {
                roleIds: search.roles.map((r) => parseInt(r, 10)),
                includeGlobalValues: false
              },

        permissionFilter:
          search.permissions.length === 0
            ? undefined
            : {
                permissionIds: search.permissions.map((p) => parseInt(p, 10))
              }
      }
    } as ApiExternalUsersSearchesPostRequest;
    return externalUsersClient.apiExternalUsersSearchesPost(request);
  };

  const { form, FormContainer, Input, MultiSelect } = useBoundForm<Search>({
    onSubmit: async (e) => {
      runSearch(e);
    },
    model: search
  });

  function cleanSearch(s: Search) {
    const model: Search = {
      ...s,
      // Only search for roles, that are valid options based on your role dropdown
      // (which can change dynamically whenever a user multiselects an application)
      roles: s.roles.filter((r) => roles?.some((ro) => ro.id === r))
    };
    return model;
  }

  function runSearch(s: Search) {
    const model = cleanSearch(s);

    setCurrentPage(1);
    saveSearch({ ...model, seq: 0 });
    setSearch({ ...model, seq: search.seq + 1 });
  }

  const handleSort = (e: SortEvent<ExternalUserSearchOrderBy>) => {
    setSorting({ direction: e.direction, field: e.field });
  };

  return (
    <Page header='Eksterne brukere' title='Eksterne brukere'>
      <Container fluid>
        <FormContainer key={search.seq} form={form}>
          <Row>
            <Col sm={12} lg={4}>
              <Input
                form={form}
                name='firstName'
                label='Fornavn'
                placeholder='Fornavn'
                onEnterPressed={(e) => {
                  runSearch({
                    ...search,
                    firstName: e,
                    seq: search.seq + 1
                  });
                }}
              />
            </Col>
            <Col sm={12} lg={4}>
              <Input
                form={form}
                name='surname'
                label='Etternavn'
                placeholder='Etternavn'
                onEnterPressed={(e) => {
                  runSearch({
                    ...search,
                    surname: e,
                    seq: search.seq + 1
                  });
                }}
              />
            </Col>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='statuses'
                label='Status'
                okLabel='Vis resultater'
                options={userStatuses}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    statuses: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={4}>
              <Input
                form={form}
                name='organization'
                label='Organisasjonsnavn'
                placeholder='Organisasjonsnavn'
                onEnterPressed={(e) => {
                  runSearch({
                    ...search,
                    organization: e,
                    seq: search.seq + 1
                  });
                }}
              />
            </Col>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='organizationGroups'
                label='Organisasjonsgruppe'
                okLabel='Vis resultater'
                options={organizationGroups}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    organizationGroups: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='applications'
                label='Applikasjon'
                okLabel='Vis resultater'
                options={applications ?? []}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    applications: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='roles'
                label='Rolle'
                okLabel='Vis resultater'
                options={roles ?? []}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    roles: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
            <Col sm={12} lg={4}>
              <MultiSelect
                form={form}
                name='permissions'
                label='Tilgang'
                okLabel='Vis resultater'
                options={permissions ?? []}
                onChange={(e) =>
                  runSearch({
                    ...search,
                    permissions: e,
                    seq: search.seq + 1
                  })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <br />
              {search.firstName && search.firstName !== '' && (
                <FilterLabel
                  key='firstName'
                  label={search.firstName}
                  onRemove={() => {
                    runSearch({
                      ...search,
                      firstName: '',
                      seq: search.seq + 1
                    });
                  }}
                />
              )}
              {search.surname && search.surname !== '' && (
                <FilterLabel
                  key='surname'
                  label={search.surname}
                  onRemove={() => {
                    runSearch({
                      ...search,
                      surname: '',
                      seq: search.seq + 1
                    });
                  }}
                />
              )}
              {search.statuses &&
                search.statuses.map((st) => (
                  <FilterLabel
                    key={`status${st}`}
                    label={userStatuses.filter((us) => us.id === st)[0]?.text}
                    onRemove={() => {
                      runSearch({
                        ...search,
                        statuses: search.statuses.filter((s) => s !== st),
                        seq: search.seq + 1
                      });
                    }}
                  />
                ))}
              {search.organization && search.organization !== '' && (
                <FilterLabel
                  key='organization'
                  label={search.organization}
                  onRemove={() => {
                    runSearch({
                      ...search,
                      organization: '',
                      seq: search.seq + 1
                    });
                  }}
                />
              )}
              {search.organizationGroups &&
                search.organizationGroups.map((og) => (
                  <FilterLabel
                    key={`organizationGroups${og}`}
                    label={organizationGroups.filter((o) => o.id === og)[0]?.text}
                    onRemove={() => {
                      runSearch({
                        ...search,
                        organizationGroups: search.organizationGroups.filter((o) => o !== og),
                        seq: search.seq + 1
                      });
                    }}
                  />
                ))}

              {search.applications &&
                search.applications.map((a) => (
                  <FilterLabel
                    key={`application${a}`}
                    label={applications.filter((app) => app.id === a)[0]?.text}
                    onRemove={() => {
                      runSearch({
                        ...search,
                        applications: search.applications.filter((app) => app !== a),
                        seq: search.seq + 1
                      });
                    }}
                  />
                ))}
              {roles &&
                search.roles &&
                search.roles.map((r) => (
                  <FilterLabel
                    key={`role${r}`}
                    label={roles.filter((ro) => ro.id === r)[0]?.text}
                    onRemove={() => {
                      runSearch({
                        ...search,
                        roles: search.roles.filter((ro) => ro !== r),
                        seq: search.seq + 1
                      });
                    }}
                  />
                ))}
              {permissions &&
                search.permissions &&
                search.permissions.map((p) => (
                  <FilterLabel
                    key={`permissions${p}`}
                    label={permissions.filter((pm) => pm.id === p)[0]?.text}
                    onRemove={() => {
                      runSearch({
                        ...search,
                        permissions: search.permissions.filter((po) => po !== p),
                        seq: search.seq + 1
                      });
                    }}
                  />
                ))}
              {hasSearchFilters(search) && (
                <FilterReset
                  key='reset'
                  label='Fjern alle'
                  onRemove={() => {
                    runSearch({
                      ...emptySearch
                    });
                  }}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Button type='submit' text='Søk' />
            </Col>
          </Row>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <PagedTable
              startIndex={startIndex}
              totalHits={usersPaged.pageInfo?.totalCount ?? 0}
              pageSize={pageSize}
              onNextClick={onNextPage}
              onPreviousClick={onPreviousPage}
            >
              <thead>
                <tr>
                  <ThSortable
                    title='Navn'
                    field={ExternalUserSearchOrderBy.DisplayName}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Status'
                    field={ExternalUserSearchOrderBy.Status}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Organisasjon'
                    field={ExternalUserSearchOrderBy.OrganizationName}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Org.nummer'
                    field={ExternalUserSearchOrderBy.OrganizationNumber}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                  <ThSortable
                    title='Org.form'
                    field={ExternalUserSearchOrderBy.OrganizationForm}
                    currentSort={sorting}
                    onSort={handleSort}
                  />
                </tr>
              </thead>

              <tbody>
                {usersPaged.data?.map((u) => (
                  <tr key={u.userId ?? ''}>
                    <td>
                      <Link to={`${RoutePaths.externalusers}/${u.userId}`}>{`${u.displayName}`}</Link>
                    </td>
                    <td>{u.status === ExternalUserStatus.Active ? 'Aktiv' : 'Inaktiv'}</td>
                    <td>{u.organizationName}</td>
                    <td>{u.organizationNumber}</td>
                    <td>{u.organizationForm}</td>
                  </tr>
                ))}
              </tbody>
            </PagedTable>
          )}
        </FormContainer>
      </Container>
    </Page>
  );
}

export default ExternalUsers;
