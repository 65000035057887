import React, { useState } from 'react';
import Page from 'components/page/Page';
import { ResourceFilter, UserAccessType, UserType } from 'api/adgang/models';
import {
  LoadingSpinner,
  useFetch,
  Table,
  formatDate,
  formatDatetime,
  Checkbox,
  ConfirmationModal
} from 'common.ui';
import RoleSelectorModal from 'components/roles/RoleSelectorModal';
import PermissionsSelectorModal from 'components/permissions/PermissionsSelectorModal';
import { internalUsersClient, usersClient } from 'api/AdgangClients';
import { Link, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap';
import { UserProperty } from 'components/users/UserProperty';
import { UserAccessTable, UserAccessResponseOrganization } from 'components/users/UserAccessTable';
import { toIdPortenInfo } from 'helpers/users/toIdPortenInfo';
import { toFullname } from 'helpers/users/toFullname';
import { concat } from 'helpers/concat';

import './InternalUser.scss';
import RoutePaths from 'RoutePaths';
import { UserEventsTable } from 'components/users/UserEventsTable';

interface IParams {
  id: string;
}

function InternalUser() {
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [showInheritedAccess, setShowInheritedAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState<UserAccessResponseOrganization>();
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [seq, setSeq] = useState(0);
  const [notifyEmail, setNotifyEmail] = useState(false);
  const { id } = useParams<IParams>();
  const [user] = useFetch(
    async () =>
      internalUsersClient.apiInternalUsersInternalUserIdGet({
        internalUserId: id
      }),
    undefined,
    false,
    [id]
  );

  const [access] = useFetch(
    async () =>
      user?.userId
        ? usersClient.apiUsersUserIdAccessGet({
            userId: user.userId
          })
        : null,
    undefined,
    false,
    [user?.userId, seq]
  );

  async function onDeleteConfirmed() {
    if (deleteAccess && user?.userId && deleteAccess.UserAccess.id) {
      if (deleteAccess.UserAccess.type === UserAccessType.Role) {
        if (deleteAccess.Organization?.organizationId) {
          await usersClient.apiUsersUserIdRolesRoleIdOrganizationOrganizationIdDelete({
            userId: user.userId,
            roleId: deleteAccess.UserAccess.id,
            organizationId: deleteAccess.Organization.organizationId
          });
        } else {
          await usersClient.apiUsersUserIdRolesRoleIdDelete({
            userId: user.userId,
            roleId: deleteAccess.UserAccess.id
          });
        }
      }
      if (deleteAccess.UserAccess.type === UserAccessType.Permission) {
        if (deleteAccess.Organization?.organizationId) {
          await usersClient.apiUsersUserIdPermissionsPermissionIdOrganizationOrganizationIdDelete({
            userId: user.userId,
            permissionId: deleteAccess.UserAccess.id,
            organizationId: deleteAccess.Organization.organizationId
          });
        } else {
          await usersClient.apiUsersUserIdPermissionsPermissionIdDelete({
            userId: user.userId,
            permissionId: deleteAccess.UserAccess.id
          });
        }
      }
      setSeq(seq + 1);
      setDeleteAccess(undefined);
    }
  }

  const idPortenUser = user && toIdPortenInfo(user);
  const userFullname = user?.sapUserData?.sapSurname
    ? toFullname(user?.sapUserData?.sapFirstname, user?.sapUserData?.sapSurname)
    : toFullname(user?.givenName, user?.surname);
  const idPortenFullname = toFullname(idPortenUser?.firstName, idPortenUser?.lastName);

  return (
    <Page header='Intern ansatt' title={['Intern ansatt', userFullname]}>
      {user && access ? (
        <>
          <ConfirmationModal
            header='Fjern tilgang'
            show={deleteAccess !== undefined}
            onCancel={() => setDeleteAccess(undefined)}
            onAccept={onDeleteConfirmed}
          >
            Er du sikker på at du vil fjerne denne{' '}
            {deleteAccess?.UserAccess?.type === UserAccessType.Permission && 'tilgangen '}
            {deleteAccess?.UserAccess?.type === UserAccessType.Role && 'rollen '}
            fra brukeren?
          </ConfirmationModal>

          <PermissionsSelectorModal
            show={showPermissionModal}
            header='Legg til tilgang'
            onCancel={() => setShowPermissionModal(false)}
            okText='Legg til tilgang'
            userId={user.userId || undefined}
            userType={UserType.Internal}
            resourceFilter={ResourceFilter.InternalUserAccessAssignment}
            onPermissionSelected={async (permission) => {
              if (user.userId) {
                await usersClient.apiUsersUserIdPermissionsPost({
                  userId: user.userId,
                  addUserPermissionRequest: {
                    notifyUserByEmail: notifyEmail,
                    permissionId: parseInt(permission.id, 10)
                  }
                });
                setSeq(seq + 1);
              }
              setShowPermissionModal(false);
            }}
            additionalJsx={
              <Checkbox
                id='notifyEmail'
                key={notifyEmail ? '1' : '0'}
                onChange={() => setNotifyEmail(!notifyEmail)}
                checked={notifyEmail}
                labelText='Send epost til bruker om tilgangstildelingen'
              />
            }
          />

          <RoleSelectorModal
            show={showRoleModal}
            header='Legg til rolle'
            onCancel={() => setShowRoleModal(false)}
            okText='Legg til rolle'
            userId={user.userId || undefined}
            userType={UserType.Internal}
            resourceFilter={ResourceFilter.InternalUserAccessAssignment}
            onRoleSelected={async (role) => {
              if (user.userId) {
                await usersClient.apiUsersUserIdRolesPost({
                  userId: user.userId,
                  addUserRoleRequest: {
                    notifyUserByEmail: notifyEmail,
                    roleId: parseInt(role.id, 10)
                  }
                });
                setSeq(seq + 1);
              }
              setShowRoleModal(false);
            }}
            additionalJsx={
              <Checkbox
                id='notifyEmail'
                key={notifyEmail ? '1' : '0'}
                onChange={() => setNotifyEmail(!notifyEmail)}
                checked={notifyEmail}
                labelText='Send epost til bruker om rolletildelingen'
              />
            }
          />

          <Container fluid>
            <Row>
              <Col lg='8'>
                <div className='content-box'>
                  <UserProperty label='Navn' value={userFullname} />
                  <UserProperty label='Epost' value={user.mail} />
                  <UserProperty label='Initialer' value={user.sapUserData?.initials} />
                  <UserProperty
                    label='Seksjon'
                    value={`${concat(' - ', user.sapUserData?.sectionCode, user.sapUserData?.sectionName)}`}
                  />
                  <UserProperty label='Arbeidssted' value={user.sapUserData?.personCity} />
                  <UserProperty label='Ansattnummer' value={user.employeeId} />
                  <UserProperty
                    label='Stilling'
                    value={`${concat(
                      ' - ',
                      user.sapUserData?.jobTitleCode,
                      user.sapUserData?.jobTitle,
                      user.sapUserData?.employmentCategory
                    )}`}
                  />
                  <UserProperty label='Sist innlogget' value={formatDatetime(user.lastLoggedInOn)} />

                  <UserProperty label='&nbsp;' value='' />

                  {idPortenUser && idPortenFullname && idPortenFullname !== userFullname && (
                    <UserProperty label='Navn (ID-porten)' value={`${idPortenFullname}`} />
                  )}
                  {idPortenUser && idPortenUser.epost && user.mail !== idPortenUser.epost && (
                    <UserProperty label='Epost (ID-porten)' value={idPortenUser?.epost} />
                  )}
                  {user.userKrrInfo && user.userKrrInfo.email && user.userKrrInfo.email !== user.mail && (
                    <UserProperty label='Epost (KRR)' value={user.userKrrInfo?.email} />
                  )}
                  {user.userKrrInfo && user.userKrrInfo.mobileNr && (
                    <UserProperty label='Telefon (KRR)' value={user.userKrrInfo?.mobileNr} />
                  )}
                </div>
              </Col>
              <Col lg='4'>
                <Table>
                  <thead>
                    <tr>
                      <th>Seksjon</th>
                      <th>Startdato</th>
                      <th>Sluttdato</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user.sectionHistory &&
                      user.sectionHistory
                        .sort((s1, s2) => {
                          if (!s1 || !s1.startDate) {
                            return -1;
                          }
                          if (!s2 || !s2.startDate) {
                            return 1;
                          }
                          return s2.startDate.getTime() - s1.startDate.getTime();
                        })
                        .map((s) => (
                          <tr key={s.startDate?.toDateString()}>
                            <td>
                              <Link to={`${RoutePaths.sections}/${s.sectionCode}`}>
                                {`${s.sectionCode} - ${s.sectionName}`}
                              </Link>
                            </td>
                            <td>{formatDate(s.startDate)}</td>
                            <td>{formatDate(s.endDate)}</td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </Col>
            </Row>

            <UserAccessTable
              access={access}
              idPortenUserId={idPortenUser?.userId}
              showInheritedAccess={showInheritedAccess}
              toggleShowInheritedAccess={() => setShowInheritedAccess(!showInheritedAccess)}
              addPermission={() => {
                setNotifyEmail(true);
                setShowPermissionModal(true);
              }}
              addRole={() => {
                setNotifyEmail(true);
                setShowRoleModal(true);
              }}
              onDeleteAccess={(a) => setDeleteAccess(a)}
            />

            {user && user.userId && <UserEventsTable userId={user.userId} refresh={seq} />}
          </Container>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </Page>
  );
}

export default InternalUser;
