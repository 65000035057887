import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {
  ClickAnalyticsPlugin,
  IClickAnalyticsConfiguration
} from '@microsoft/applicationinsights-clickanalytics-js';
import Environment from 'Environment';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory({ basename: '' });

const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig: IClickAnalyticsConfiguration = {
  autoCapture: true
};

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: Environment.ApplicationInsightsConnectionString,
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
      [clickPluginInstance.identifier]: clickPluginConfig
    },
    enableAutoRouteTracking: true,
    disableAjaxTracking: false,
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true
  }
});
appInsights.loadAppInsights();

appInsights.addTelemetryInitializer((env: ITelemetryItem) => {
  env.tags = env.tags || {};
  // env.tags['ai.cloud.role'] = '<app-role-frontend>';
  //custom props
  env.data = env.data || {};
  // env.data['ms-appName'] = '<frontend-name>';
  // env.data['ms-user'] = '<frontend-auth-user>';
  // env.data['ms-userid'] = '<frontend-auth-userid>';
});

export { reactPlugin, appInsights };
