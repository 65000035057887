import React from 'react';
import Page from 'components/page/Page';
import PermissionsForm from 'components/permissions/PermissionsForm';
import { LoadingSpinner, useFetch } from 'common.ui';
import { PermissionUpdateRequest } from 'api/adgang/models';
import { permissionsClient } from 'api/AdgangClients';
import { useHistory, useParams } from 'react-router-dom';
import RoutePaths from 'RoutePaths';

interface IParams {
  id: string;
}

function UpdatePermission() {
  const history = useHistory();
  const { id } = useParams<IParams>();

  const [permission] = useFetch(
    async () => permissionsClient.getPermissionbyId({ id: +id }),
    undefined,
    false,
    [id]
  );

  async function onSave(model: PermissionUpdateRequest) {
    await permissionsClient.apiPermissionsIdPut({
      id: +id,
      permissionUpdateRequest: model
    });
    history.push(RoutePaths.permissions);
  }

  if (!permission) return <LoadingSpinner />;

  return (
    <Page header='Detaljer om tilgang' title={['Titlgang', 'Detaljer', permission?.name || '']}>
      <PermissionsForm onSave={onSave} onSaveLabel='Lagre' initialValue={permission} isUpdate />
    </Page>
  );
}

export default UpdatePermission;
