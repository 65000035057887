/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalUserNameUpdateRequest
 */
export interface ExternalUserNameUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ExternalUserNameUpdateRequest
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalUserNameUpdateRequest
     */
    lastName?: string | null;
}

export function ExternalUserNameUpdateRequestFromJSON(json: any): ExternalUserNameUpdateRequest {
    return ExternalUserNameUpdateRequestFromJSONTyped(json, false);
}

export function ExternalUserNameUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalUserNameUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
    };
}

export function ExternalUserNameUpdateRequestToJSON(value?: ExternalUserNameUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
    };
}


